import {
  PropsWithChildren,
  useMemo,
  useState
} from "react";
import {
  SpotlightAction,
  SpotlightActionProps,
  SpotlightProvider
} from "@mantine/spotlight";
import {IconSearch} from "@tabler/icons-react";

import {useDebouncedValue} from "@mantine/hooks";
import {router} from "@inertiajs/react";
import axios from "axios";
import {
  Group,
  UnstyledButton,
  Text,
  createStyles,
  rem,
  Badge
} from "@mantine/core";
const CustomSpotlightProvider = ({ children }: PropsWithChildren) => {
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 300);
  const [results, setResults] = useState<SpotlightAction[]>([])

  useMemo(() => {
    if (debouncedQuery){
      axios.post(route('search'), {
        query: debouncedQuery
      }).then((res)=>{
        console.log(res.data);
        setResults(res.data.map((hit: any) => ({
          id: hit.id, title: hit.name, description:hit.subject, status:hit.status, status_label:hit.status_label, cycle: hit.cycle,type: hit.typeLabel, onTrigger: ()=> {
            router.visit(route('applications.show', { cycle: hit.cycle.id, application: hit.id }));
          }
        } as SpotlightAction)));
      });
    }
  }, [debouncedQuery]);

  return(
    <SpotlightProvider
      filter={() =>
        debouncedQuery?results:[]
      }
      actions={[]}
      query={query}
      onQueryChange={setQuery}
      searchIcon={<IconSearch size="1.2rem" />}
      searchPlaceholder="Keresés..."
      shortcut={['mod + K', '/']}
      nothingFoundMessage="Nincs találat..."
      closeOnClickOutside={true}
      closeOnEscape={true}
      closeOnActionTrigger={true}
      actionComponent={CustomAction}
    >
      {children}
    </SpotlightProvider>
  )
}


const useStyles = createStyles((theme) => ({
  action: {
    position: 'relative',
    display: 'block',
    width: '100%',
    cursor:'pointer',
    marginBottom: 4,
    padding: `${rem(10)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2],

    }),

    '&[data-hovered]': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2],
    },
  },
}));

function CustomAction({
                        action,
                        styles,
                        classNames,
                        hovered,
                        onTrigger,
                        highlightQuery,
                        ...others
                      }: SpotlightActionProps) {
  const { classes } = useStyles();

  return (
    <UnstyledButton
      className={classes.action}
      data-hovered={hovered || undefined}
      tabIndex={-1}
      onMouseDown={(event) => {
        event.preventDefault();
      }}
      onClick={onTrigger}
      {...others}
    >
      <Group noWrap>
        <div style={{ flex: 1 }}>
          <div className={"flex flex-col"}>
            <Text className={"mr-3"}>{action.title}</Text>
            <Text color={"dimmed"} size={"sm"}>{action.type} - {action.cycle.name}</Text>
          </div>

          {action.description && (
            <Text color="dimmed" size="xs">
              {action.description}
            </Text>
          )}
        </div>

        {action.status && <Badge variant={"light"} color={action.status === 'accepted'?'green':action.status ==='pending'?'gray':'red'}>{action.status_label}</Badge>}
      </Group>
    </UnstyledButton>
  );
}

export default CustomSpotlightProvider

import ApplicationLogo from "@/Components/ApplicationLogo";

const ErrorPage = ()=>{
  return (
    <section className={"bg-white h-screen min-h-screen flex justify-center items-center"}>
      <div className={"py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 gap-6 flex flex-col"}>
          <ApplicationLogo className={"mx-auto h-8"}/>
        <div className={"mx-auto max-w-screen-sm text-center"}>
          <h1
            className={"mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-brand-600 "}>Oops!</h1>
          <p className={"mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl"}>Valami hiba történt</p>
          <p className={"mb-4 text-lg font-light text-gray-500"}>Sajnáljuk, valami hiba történt, kérjük próbálja meg újra vagy vegye fel velünk a kapcsolatot.</p>
          <a href={"#"}
             onClick={()=>{history.back();return false;}}
             className={"inline-flex text-white bg-brand-600 hover:bg-brand-800 focus:ring-4 focus:outline-none focus:ring-brand-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-brand-600 my-4"}>
            Vissza</a>
        </div>
      </div>
    </section>
  )
}

export default ErrorPage

import "./bootstrap";
import "../css/app.css";

import {createRoot} from "react-dom/client";
import {
  createEmotionCache,
  MantineProvider,
  MantineThemeOverride
} from "@mantine/core";
import {Notifications} from "@mantine/notifications";
import {createInertiaApp} from "@inertiajs/react";
import {resolvePageComponent} from "laravel-vite-plugin/inertia-helpers";
import {ModalsProvider} from "@mantine/modals";
import CustomSpotlightProvider from "@/Components/CustomSpotlightProvider";
import * as Sentry from "@sentry/react";
import React from "react";
import ErrorPage from "@/Components/ErrorPage";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
  environment: import.meta.env.MODE,
  integrations: [
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});


const appName =
  window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

const myTheme: MantineThemeOverride = {
  fontFamily: "Figtree",
  colors: {
    brand: [
      "#D2CEF5",
      "#BEB9F1",
      "#968FE9",
      "#6F64E0",
      "#473AD7",
      "#3225B9",
      "#261D8F",
      "#1B1464",
      "#0E0B35",
      "#020106",
    ],
    light: [
      "#eadcce",
      "#f1e6db",
      "#e2d2c2",
      "#d9c7b6",
      "#cfbdac",
      "#c4b3a2",
      "#b8a99a",
      "#ab9f92",
      "#9e948b",
      "#8f8a85",
    ],
    orange: [
      "#FEF7EE",
      "#FEEDDB",
      "#FCDAB3",
      "#FBC78C",
      "#F9B464",
      "#F8A13D",
      "#EA8108",
      "#AA5E06",
      "#6A3B04",
      "#2A1702",
    ],
  },
  colorScheme: "light",
  primaryShade: 6,
  primaryColor: "brand",
  defaultRadius: 4,
};

const appendCache = createEmotionCache({ key: "mantine", prepend: false });

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}.tsx`,
      import.meta.glob("./Pages/**/*.tsx")
    ),
  setup({ el, App, props }) {
    const root = createRoot(el);
    root.render(
      <Sentry.ErrorBoundary fallback={ErrorPage} showDialog>
        <MantineProvider
          theme={myTheme}
          withGlobalStyles
          withNormalizeCSS
          emotionCache={appendCache}
        >
          <CustomSpotlightProvider>
            <ModalsProvider>
              <Notifications position="bottom-center" />
              <App {...props} />
            </ModalsProvider>
          </CustomSpotlightProvider>
        </MantineProvider>
      </Sentry.ErrorBoundary>
    );
  },
  progress: {
    color: "#1B1464",
  },
});
